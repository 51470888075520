export const routes = [
  {
    name: 'admin-accessories-id-edit',
    path: '/admin/accessories/:id/edit',
    component: () => import('~/pages/admin/accessories/[id]/edit.vue'),
  },
  {
    name: 'admin-accessories',
    path: '/admin/accessories',
    component: () => import('~/pages/admin/accessories/index.vue'),
  },
  {
    name: 'admin-api_usages-statistics',
    path: '/admin/api_usages/statistics',
    component: () => import('~/pages/admin/api_usages/statistics.vue'),
  },
  {
    name: 'admin-api_usages-unused_endpoints',
    path: '/admin/api_usages/unused_endpoints',
    component: () => import('~/pages/admin/api_usages/unused_endpoints.vue'),
  },
  {
    name: 'admin-bell_account_executives',
    path: '/admin/bell_account_executives',
    component: () => import('~/pages/admin/bell_account_executives/index.vue'),
  },
  {
    name: 'admin-carriers-carrierId-carrier_features-carrierFeatureId-edit',
    path: '/admin/carriers/:carrierId/carrier_features/:carrierFeatureId/edit',
    component: () =>
      import('~/pages/admin/carriers/[carrierId]/carrier_features/[carrierFeatureId]/edit.vue'),
  },
  {
    name: 'admin-carriers-carrierId-carrier_features',
    path: '/admin/carriers/:carrierId/carrier_features',
    component: () => import('~/pages/admin/carriers/[carrierId]/carrier_features/index.vue'),
  },
  {
    name: 'admin-carriers-carrierId-carrier_rate_plans-carrierRatePlanId-edit',
    path: '/admin/carriers/:carrierId/carrier_rate_plans/:carrierRatePlanId/edit',
    component: () =>
      import('~/pages/admin/carriers/[carrierId]/carrier_rate_plans/[carrierRatePlanId]/edit.vue'),
  },
  {
    name: 'admin-carriers-carrierId-carrier_rate_plans',
    path: '/admin/carriers/:carrierId/carrier_rate_plans',
    component: () => import('~/pages/admin/carriers/[carrierId]/carrier_rate_plans/index.vue'),
  },
  {
    name: 'admin-carriers',
    path: '/admin/carriers',
    component: () => import('~/pages/admin/carriers/index.vue'),
  },
  {
    name: 'admin-customer_groups-id-edit',
    path: '/admin/customer_groups/:id/edit',
    component: () => import('~/pages/admin/customer_groups/[id]/edit.vue'),
  },
  {
    name: 'admin-customer_groups',
    path: '/admin/customer_groups',
    component: () => import('~/pages/admin/customer_groups/index.vue'),
  },
  {
    name: 'admin-customer_groups-new',
    path: '/admin/customer_groups/new',
    component: () => import('~/pages/admin/customer_groups/new.vue'),
  },
  {
    name: 'admin-delete_devices',
    path: '/admin/delete_devices',
    component: () => import('~/pages/admin/delete_devices/index.vue'),
  },
  {
    name: 'admin-device_models-id-edit',
    path: '/admin/device_models/:id/edit',
    component: () => import('~/pages/admin/device_models/[id]/edit.vue'),
  },
  {
    name: 'admin-device_models',
    path: '/admin/device_models',
    component: () => import('~/pages/admin/device_models/index.vue'),
  },
  {
    name: 'admin-device_models-new',
    path: '/admin/device_models/new',
    component: () => import('~/pages/admin/device_models/new.vue'),
  },
  {
    name: 'admin-emails-id',
    path: '/admin/emails/:id',
    component: () => import('~/pages/admin/emails/[id].vue'),
  },
  {
    name: 'admin-emails',
    path: '/admin/emails',
    component: () => import('~/pages/admin/emails/index.vue'),
  },
  {
    name: 'admin-expenses',
    path: '/admin/expenses',
    component: () => import('~/pages/admin/expenses/index.vue'),
  },
  {
    name: 'admin-expenses-new',
    path: '/admin/expenses/new',
    component: () => import('~/pages/admin/expenses/new.vue'),
  },
  {
    name: 'admin-global_notifications-id-edit',
    path: '/admin/global_notifications/:id/edit',
    component: () => import('~/pages/admin/global_notifications/[id]/edit.vue'),
  },
  {
    name: 'admin-global_notifications',
    path: '/admin/global_notifications',
    component: () => import('~/pages/admin/global_notifications/index.vue'),
  },
  {
    name: 'admin-global_notifications-new',
    path: '/admin/global_notifications/new',
    component: () => import('~/pages/admin/global_notifications/new.vue'),
  },
  {
    name: 'admin-management_companies',
    path: '/admin/management_companies',
    component: () => import('~/pages/admin/management_companies/index.vue'),
  },
  {
    name: 'admin-resque_jobs-id',
    path: '/admin/resque_jobs/:id',
    component: () => import('~/pages/admin/resque_jobs/[id].vue'),
  },
  {
    name: 'admin-resque_jobs-extended_statistics',
    path: '/admin/resque_jobs/extended_statistics',
    component: () => import('~/pages/admin/resque_jobs/extended_statistics.vue'),
  },
  {
    name: 'admin-resque_jobs-history',
    path: '/admin/resque_jobs/history',
    component: () => import('~/pages/admin/resque_jobs/history.vue'),
  },
  {
    name: 'admin-resque_jobs',
    path: '/admin/resque_jobs',
    component: () => import('~/pages/admin/resque_jobs/index.vue'),
  },
  {
    name: 'admin-roaming_packages-id-edit',
    path: '/admin/roaming_packages/:id/edit',
    component: () => import('~/pages/admin/roaming_packages/[id]/edit.vue'),
  },
  {
    name: 'admin-roaming_packages',
    path: '/admin/roaming_packages',
    component: () => import('~/pages/admin/roaming_packages/index.vue'),
  },
  {
    name: 'admin-roaming_packages-new',
    path: '/admin/roaming_packages/new',
    component: () => import('~/pages/admin/roaming_packages/new.vue'),
  },
  {
    name: 'admin-roaming_zones-id-edit',
    path: '/admin/roaming_zones/:id/edit',
    component: () => import('~/pages/admin/roaming_zones/[id]/edit.vue'),
  },
  {
    name: 'admin-roaming_zones-new',
    path: '/admin/roaming_zones/new',
    component: () => import('~/pages/admin/roaming_zones/new.vue'),
  },
  {
    name: 'admin-sms_messages',
    path: '/admin/sms_messages',
    component: () => import('~/pages/admin/sms_messages.vue'),
  },
  {
    name: 'admin-terms_and_conditions_agreements',
    path: '/admin/terms_and_conditions_agreements',
    component: () => import('~/pages/admin/terms_and_conditions_agreements/index.vue'),
  },
  {
    name: 'admin-terms_and_conditions_agreements-new',
    path: '/admin/terms_and_conditions_agreements/new',
    component: () => import('~/pages/admin/terms_and_conditions_agreements/new.vue'),
  },
  {
    name: 'customer-numericId-automator-rules-ruleId-edit',
    path: '/customer/:numericId/automator/rules/:ruleId/edit',
    component: () => import('~/pages/customer/[numericId]/automator/rules/[ruleId]/edit.vue'),
  },
  {
    name: 'customer-numericId-automator-rules',
    path: '/customer/:numericId/automator/rules',
    component: () => import('~/pages/customer/[numericId]/automator/rules/index.vue'),
  },
  {
    name: 'customer-numericId-automator-rules-new',
    path: '/customer/:numericId/automator/rules/new',
    component: () => import('~/pages/customer/[numericId]/automator/rules/new.vue'),
  },
  {
    name: 'customer-numericId-background_jobs',
    path: '/customer/:numericId/background_jobs',
    component: () => import('~/pages/customer/[numericId]/background_jobs/index.vue'),
  },
  {
    name: 'customer-numericId-bell_hardware-bell_hardware_report',
    path: '/customer/:numericId/bell_hardware/bell_hardware_report',
    component: () =>
      import('~/pages/customer/[numericId]/bell_hardware/bell_hardware_report/index.vue'),
  },
  {
    name: 'customer-numericId-bell_hardware-business_reporting',
    path: '/customer/:numericId/bell_hardware/business_reporting',
    component: () =>
      import('~/pages/customer/[numericId]/bell_hardware/business_reporting/index.vue'),
  },
  {
    name: 'customer-numericId-bell_hardware-hardware_reporting',
    path: '/customer/:numericId/bell_hardware/hardware_reporting',
    component: () =>
      import('~/pages/customer/[numericId]/bell_hardware/hardware_reporting/index.vue'),
  },
  {
    name: 'customer-numericId-business-business_accounts-id-edit',
    path: '/customer/:numericId/business/business_accounts/:id/edit',
    component: () =>
      import('~/pages/customer/[numericId]/business/business_accounts/[id]/edit.vue'),
  },
  {
    name: 'customer-numericId-business-business_accounts-business_account_groups-groupId-edit',
    path: '/customer/:numericId/business/business_accounts/business_account_groups/:groupId/edit',
    component: () =>
      import(
        '~/pages/customer/[numericId]/business/business_accounts/business_account_groups/[groupId]/edit.vue'
      ),
  },
  {
    name: 'customer-numericId-business-business_accounts-business_account_groups-groupId',
    path: '/customer/:numericId/business/business_accounts/business_account_groups/:groupId',
    component: () =>
      import(
        '~/pages/customer/[numericId]/business/business_accounts/business_account_groups/[groupId]/index.vue'
      ),
  },
  {
    name: 'customer-numericId-business-business_accounts-business_account_groups',
    path: '/customer/:numericId/business/business_accounts/business_account_groups',
    component: () =>
      import(
        '~/pages/customer/[numericId]/business/business_accounts/business_account_groups/index.vue'
      ),
  },
  {
    name: 'customer-numericId-business-business_accounts',
    path: '/customer/:numericId/business/business_accounts',
    component: () => import('~/pages/customer/[numericId]/business/business_accounts/index.vue'),
  },
  {
    name: 'customer-numericId-business-business_accounts-logins-id-edit',
    path: '/customer/:numericId/business/business_accounts/logins/:id/edit',
    component: () =>
      import('~/pages/customer/[numericId]/business/business_accounts/logins/[id]/edit.vue'),
  },
  {
    name: 'customer-numericId-business-business_accounts-logins',
    path: '/customer/:numericId/business/business_accounts/logins',
    component: () =>
      import('~/pages/customer/[numericId]/business/business_accounts/logins/index.vue'),
  },
  {
    name: 'customer-numericId-business-business_accounts-logins-new',
    path: '/customer/:numericId/business/business_accounts/logins/new',
    component: () =>
      import('~/pages/customer/[numericId]/business/business_accounts/logins/new.vue'),
  },
  {
    name: 'customer-numericId-business-business_accounts-new',
    path: '/customer/:numericId/business/business_accounts/new',
    component: () => import('~/pages/customer/[numericId]/business/business_accounts/new.vue'),
  },
  {
    name: 'customer-numericId-business-locations-id-edit',
    path: '/customer/:numericId/business/locations/:id/edit',
    component: () => import('~/pages/customer/[numericId]/business/locations/[id]/edit.vue'),
  },
  {
    name: 'customer-numericId-business-locations-new',
    path: '/customer/:numericId/business/locations/new',
    component: () => import('~/pages/customer/[numericId]/business/locations/new.vue'),
  },
  {
    name: 'customer-numericId-custom_billing_settings-edit',
    path: '/customer/:numericId/custom_billing_settings/edit',
    component: () => import('~/pages/customer/[numericId]/custom_billing_settings/edit.vue'),
  },
  {
    name: 'customer-numericId-delegations-id',
    path: '/customer/:numericId/delegations/:id',
    component: () => import('~/pages/customer/[numericId]/delegations/[id]/index.vue'),
  },
  {
    name: 'customer-numericId-delegations',
    path: '/customer/:numericId/delegations',
    component: () => import('~/pages/customer/[numericId]/delegations/index.vue'),
  },
  {
    name: 'customer-numericId-delegations-new_hardware_delegation',
    path: '/customer/:numericId/delegations/new_hardware_delegation',
    component: () => import('~/pages/customer/[numericId]/delegations/new_hardware_delegation.vue'),
  },
  {
    name: 'customer-numericId-devices-rate_plans-monitor',
    path: '/customer/:numericId/devices/rate_plans/monitor',
    component: () => import('~/pages/customer/[numericId]/devices/rate_plans/monitor/index.vue'),
  },
  {
    name: 'customer-numericId-hardware_orders-new-index__turned_off',
    path: '/customer/:numericId/hardware_orders/new/index__turned_off',
    component: () =>
      import('~/pages/customer/[numericId]/hardware_orders/new/index__turned_off.vue'),
  },
  {
    name: 'customer-numericId-inventory-id-edit',
    path: '/customer/:numericId/inventory/:id/edit',
    component: () => import('~/pages/customer/[numericId]/inventory/[id]/edit.vue'),
  },
  {
    name: 'customer-numericId-inventory-id',
    path: '/customer/:numericId/inventory/:id',
    component: () => import('~/pages/customer/[numericId]/inventory/[id]/index.vue'),
  },
  {
    name: 'customer-numericId-inventory-new',
    path: '/customer/:numericId/inventory/new',
    component: () => import('~/pages/customer/[numericId]/inventory/new.vue'),
  },
  {
    name: 'customer-numericId-orders',
    path: '/customer/:numericId/orders',
    component: () => import('~/pages/customer/[numericId]/orders/index.vue'),
  },
  {
    name: 'customer-numericId-reports-billingCycleId-business_unit',
    path: '/customer/:numericId/reports/:billingCycleId/business_unit',
    component: () =>
      import('~/pages/customer/[numericId]/reports/[billingCycleId]/business_unit/index.vue'),
  },
  {
    name: 'customer-numericId-reports-billingCycleId-end_user_no_usage',
    path: '/customer/:numericId/reports/:billingCycleId/end_user_no_usage',
    component: () =>
      import('~/pages/customer/[numericId]/reports/[billingCycleId]/end_user_no_usage/index.vue'),
  },
  {
    name: 'customer-numericId-reports-billingCycleId-executive_summary_demo',
    path: '/customer/:numericId/reports/:billingCycleId/executive_summary_demo',
    component: () =>
      import(
        '~/pages/customer/[numericId]/reports/[billingCycleId]/executive_summary_demo/index.vue'
      ),
  },
  {
    name: 'customer-numericId-reports-accounting-accounting-report-push',
    path: '/customer/:numericId/reports/accounting/accounting-report-push',
    component: () =>
      import('~/pages/customer/[numericId]/reports/accounting/accounting-report-push.vue'),
  },
  {
    name: 'customer-numericId-reports-accounting-devices-details-download-history',
    path: '/customer/:numericId/reports/accounting/devices-details/download-history',
    component: () =>
      import(
        '~/pages/customer/[numericId]/reports/accounting/devices-details/download-history.vue'
      ),
  },
  {
    name: 'customer-numericId-reports-accounting-devices-details-payment-details',
    path: '/customer/:numericId/reports/accounting/devices-details/payment-details',
    component: () =>
      import('~/pages/customer/[numericId]/reports/accounting/devices-details/payment-details.vue'),
  },
  {
    name: 'customer-numericId-reports-accounting-devices-details-send-history',
    path: '/customer/:numericId/reports/accounting/devices-details/send-history',
    component: () =>
      import('~/pages/customer/[numericId]/reports/accounting/devices-details/send-history.vue'),
  },
  {
    name: 'customer-numericId-reports-accounting-devices-details',
    path: '/customer/:numericId/reports/accounting/devices-details',
    component: () => import('~/pages/customer/[numericId]/reports/accounting/devices-details.vue'),
  },
  {
    name: 'customer-numericId-reports-accounting-devices-lines',
    path: '/customer/:numericId/reports/accounting/devices-lines',
    component: () => import('~/pages/customer/[numericId]/reports/accounting/devices-lines.vue'),
  },
  {
    name: 'customer-numericId-reports-accounting-generate-report-modal',
    path: '/customer/:numericId/reports/accounting/generate-report-modal',
    component: () =>
      import('~/pages/customer/[numericId]/reports/accounting/generate-report-modal.vue'),
  },
  {
    name: 'customer-numericId-reports-accounting',
    path: '/customer/:numericId/reports/accounting',
    component: () => import('~/pages/customer/[numericId]/reports/accounting/index.vue'),
  },
  {
    name: 'customer-numericId-reports-accounting-push-history',
    path: '/customer/:numericId/reports/accounting/push-history',
    component: () => import('~/pages/customer/[numericId]/reports/accounting/push-history.vue'),
  },
  {
    name: 'customer-numericId-reports-cost_variance',
    path: '/customer/:numericId/reports/cost_variance',
    component: () => import('~/pages/customer/[numericId]/reports/cost_variance/index.vue'),
  },
  {
    name: 'customer-numericId-reports-custom',
    path: '/customer/:numericId/reports/custom',
    component: () => import('~/pages/customer/[numericId]/reports/custom/index.vue'),
  },
  {
    name: 'customer-numericId-reports-daily_activity',
    path: '/customer/:numericId/reports/daily_activity',
    component: () => import('~/pages/customer/[numericId]/reports/daily_activity/index.vue'),
  },
  {
    name: 'customer-numericId-reports-data_behavior-billingCycleId',
    path: '/customer/:numericId/reports/data_behavior/:billingCycleId',
    component: () =>
      import('~/pages/customer/[numericId]/reports/data_behavior/[billingCycleId]/index.vue'),
  },
  {
    name: 'customer-numericId-reports-data_daily-cycle-cycleId-data-billingDataId',
    path: '/customer/:numericId/reports/data_daily/cycle/:cycleId/data/:billingDataId',
    component: () =>
      import(
        '~/pages/customer/[numericId]/reports/data_daily/cycle/[cycleId]/data/[billingDataId]/index.vue'
      ),
  },
  {
    name: 'customer-numericId-reports-data_usage-cycleId',
    path: '/customer/:numericId/reports/data_usage/:cycleId',
    component: () => import('~/pages/customer/[numericId]/reports/data_usage/[cycleId]/index.vue'),
  },
  {
    name: 'customer-numericId-reports-my_team_overview',
    path: '/customer/:numericId/reports/my_team_overview',
    component: () => import('~/pages/customer/[numericId]/reports/my_team_overview/index.vue'),
  },
  {
    name: 'customer-numericId-reports-service_ticket_reporting',
    path: '/customer/:numericId/reports/service_ticket_reporting',
    component: () =>
      import('~/pages/customer/[numericId]/reports/service_ticket_reporting/index.vue'),
  },
  {
    name: 'customer-numericId-reports-upgrade_eligibility',
    path: '/customer/:numericId/reports/upgrade_eligibility',
    component: () => import('~/pages/customer/[numericId]/reports/upgrade_eligibility/index.vue'),
  },
  {
    name: 'customer-numericId-service_now-orders-uuid-confirmation_of_update',
    path: '/customer/:numericId/service_now/orders/:uuid/confirmation_of_update',
    component: () =>
      import(
        '~/pages/customer/[numericId]/service_now/orders/[uuid]/confirmation_of_update/index.vue'
      ),
  },
  {
    name: 'customer-numericId-service_now-orders-uuid-general_info',
    path: '/customer/:numericId/service_now/orders/:uuid/general_info',
    component: () =>
      import('~/pages/customer/[numericId]/service_now/orders/[uuid]/general_info/index.vue'),
  },
  {
    name: 'customer-numericId-service_now-orders-uuid-items-itemId-edit',
    path: '/customer/:numericId/service_now/orders/:uuid/items/:itemId/edit',
    component: () =>
      import(
        '~/pages/customer/[numericId]/service_now/orders/[uuid]/items/[itemId]/edit/index.vue'
      ),
  },
  {
    name: 'customer-numericId-service_now-orders',
    path: '/customer/:numericId/service_now/orders',
    component: () => import('~/pages/customer/[numericId]/service_now/orders/index.vue'),
  },
  {
    name: 'customer-numericId-service_now-orders-new',
    path: '/customer/:numericId/service_now/orders/new',
    component: () => import('~/pages/customer/[numericId]/service_now/orders/new/index.vue'),
  },
  {
    name: 'customer-numericId-settings-accounting_types-id-edit',
    path: '/customer/:numericId/settings/accounting_types/:id/edit',
    component: () => import('~/pages/customer/[numericId]/settings/accounting_types/[id]/edit.vue'),
  },
  {
    name: 'customer-numericId-settings-accounting_types-new',
    path: '/customer/:numericId/settings/accounting_types/new',
    component: () => import('~/pages/customer/[numericId]/settings/accounting_types/new.vue'),
  },
  {
    name: 'customer-numericId-settings-api',
    path: '/customer/:numericId/settings/api',
    component: () => import('~/pages/customer/[numericId]/settings/api/index.vue'),
  },
  {
    name: 'customer-numericId-settings-business_unit_rules',
    path: '/customer/:numericId/settings/business_unit_rules',
    component: () => import('~/pages/customer/[numericId]/settings/business_unit_rules/index.vue'),
  },
  {
    name: 'customer-numericId-settings-carrier_rate_plan_settings',
    path: '/customer/:numericId/settings/carrier_rate_plan_settings',
    component: () => import('~/pages/customer/[numericId]/settings/carrier_rate_plan_settings.vue'),
  },
  {
    name: 'customer-numericId-settings-changelog',
    path: '/customer/:numericId/settings/changelog',
    component: () => import('~/pages/customer/[numericId]/settings/changelog.vue'),
  },
  {
    name: 'customer-numericId-settings-customer_groups-edit',
    path: '/customer/:numericId/settings/customer_groups/edit',
    component: () => import('~/pages/customer/[numericId]/settings/customer_groups/edit.vue'),
  },
  {
    name: 'customer-numericId-settings-customer_groups-new',
    path: '/customer/:numericId/settings/customer_groups/new',
    component: () => import('~/pages/customer/[numericId]/settings/customer_groups/new.vue'),
  },
  {
    name: 'customer-numericId-settings-customer_tags',
    path: '/customer/:numericId/settings/customer_tags',
    component: () => import('~/pages/customer/[numericId]/settings/customer_tags.vue'),
  },
  {
    name: 'customer-numericId-settings-data_pool_thresholds',
    path: '/customer/:numericId/settings/data_pool_thresholds',
    component: () => import('~/pages/customer/[numericId]/settings/data_pool_thresholds/index.vue'),
  },
  {
    name: 'customer-numericId-settings-dealers',
    path: '/customer/:numericId/settings/dealers',
    component: () => import('~/pages/customer/[numericId]/settings/dealers.vue'),
  },
  {
    name: 'customer-numericId-settings-email_setup',
    path: '/customer/:numericId/settings/email_setup',
    component: () => import('~/pages/customer/[numericId]/settings/email_setup.vue'),
  },
  {
    name: 'customer-numericId-settings-emails',
    path: '/customer/:numericId/settings/emails',
    component: () => import('~/pages/customer/[numericId]/settings/emails.vue'),
  },
  {
    name: 'customer-numericId-settings-helpdesk',
    path: '/customer/:numericId/settings/helpdesk',
    component: () => import('~/pages/customer/[numericId]/settings/helpdesk.vue'),
  },
  {
    name: 'customer-numericId-settings-individual_users',
    path: '/customer/:numericId/settings/individual_users',
    component: () => import('~/pages/customer/[numericId]/settings/individual_users.vue'),
  },
  {
    name: 'customer-numericId-settings-integration_settings',
    path: '/customer/:numericId/settings/integration_settings',
    component: () => import('~/pages/customer/[numericId]/settings/integration_settings/index.vue'),
  },
  {
    name: 'customer-numericId-settings-mdm_configs',
    path: '/customer/:numericId/settings/mdm_configs',
    component: () => import('~/pages/customer/[numericId]/settings/mdm_configs/index.vue'),
  },
  {
    name: 'customer-numericId-settings-roaming',
    path: '/customer/:numericId/settings/roaming',
    component: () => import('~/pages/customer/[numericId]/settings/roaming.vue'),
  },
  {
    name: 'customer-numericId-settings-sso-saml-id-edit',
    path: '/customer/:numericId/settings/sso/saml/:id/edit',
    component: () => import('~/pages/customer/[numericId]/settings/sso/saml/[id]/edit.vue'),
  },
  {
    name: 'customer-numericId-settings-sso-saml',
    path: '/customer/:numericId/settings/sso/saml',
    component: () => import('~/pages/customer/[numericId]/settings/sso/saml/index.vue'),
  },
  {
    name: 'customer-numericId-settings-sso_and_wirelines',
    path: '/customer/:numericId/settings/sso_and_wirelines',
    component: () => import('~/pages/customer/[numericId]/settings/sso_and_wirelines.vue'),
  },
  {
    name: 'customer-numericId-status_update_requests',
    path: '/customer/:numericId/status_update_requests',
    component: () => import('~/pages/customer/[numericId]/status_update_requests/index.vue'),
  },
  {
    name: 'customer-numericId-transfer_of_responsibility-to_person',
    path: '/customer/:numericId/transfer_of_responsibility/to_person',
    component: () =>
      import('~/pages/customer/[numericId]/transfer_of_responsibility/to_person/index.vue'),
  },
  {
    name: 'customer-numericId-users-userId',
    path: '/customer/:numericId/users/:userId',
    component: () => import('~/pages/customer/[numericId]/users/[userId]/index.vue'),
  },
  {
    name: 'customer-numericId-wireline-bills',
    path: '/customer/:numericId/wireline-bills',
    component: () => import('~/pages/customer/[numericId]/wireline-bills/index.vue'),
  },
  {
    name: 'customer_workflows',
    path: '/customer_workflows',
    component: () => import('~/pages/customer_workflows/index.vue'),
  },
  {
    name: 'customer_workflows-wirelines',
    path: '/customer_workflows/wirelines',
    component: () => import('~/pages/customer_workflows/wirelines.vue'),
  },
  {
    name: 'customers-registers-new',
    path: '/customers/registers/new',
    component: () => import('~/pages/customers/registers/new/index.vue'),
  },
  {
    name: 'data_usage-data_block_dashboard',
    path: '/data_usage/data_block_dashboard',
    component: () => import('~/pages/data_usage/data_block_dashboard/index.vue'),
  },
  {
    name: 'dmi_teams-id-edit',
    path: '/dmi_teams/:id/edit',
    component: () => import('~/pages/dmi_teams/[id]/edit.vue'),
  },
  { name: 'dmi_teams', path: '/dmi_teams', component: () => import('~/pages/dmi_teams/index.vue') },
  {
    name: 'dmi_teams-new',
    path: '/dmi_teams/new',
    component: () => import('~/pages/dmi_teams/new.vue'),
  },
  {
    name: 'helpdesk-data_pools',
    path: '/helpdesk/data_pools',
    component: () => import('~/pages/helpdesk/data_pools.vue'),
  },
  {
    name: 'helpdesk-device_status_update_requests',
    path: '/helpdesk/device_status_update_requests',
    component: () => import('~/pages/helpdesk/device_status_update_requests.vue'),
  },
  {
    name: 'helpdesk-possible_data_pool_sources',
    path: '/helpdesk/possible_data_pool_sources',
    component: () => import('~/pages/helpdesk/possible_data_pool_sources.vue'),
  },
  {
    name: 'managerial_disputes',
    path: '/managerial_disputes',
    component: () => import('~/pages/managerial_disputes/index.vue'),
  },
  {
    name: 'products-id-edit',
    path: '/products/:id/edit',
    component: () => import('~/pages/products/[id]/edit.vue'),
  },
  { name: 'products', path: '/products', component: () => import('~/pages/products/index.vue') },
  {
    name: 'products-new',
    path: '/products/new',
    component: () => import('~/pages/products/new.vue'),
  },
  {
    name: 'reports-country-secureHash',
    path: '/reports/country/:secureHash',
    component: () => import('~/pages/reports/country/[secureHash]/index.vue'),
  },
  {
    name: 'reports-end_user_no_usage-secureHash',
    path: '/reports/end_user_no_usage/:secureHash',
    component: () => import('~/pages/reports/end_user_no_usage/[secureHash]/index.vue'),
  },
]
